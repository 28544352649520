.status-paid,
.status-done,
.status-success,
.status-now,
.status-publish,
.status-green {
  color: #2BB673;
  background-color: #E7F9F0;
  padding: 2px 4px;
  border-radius: 14px;
  text-align: center;
}
.status-pending,
.status-unpublish,
.status-orange {
  color: #E6A80A;
  background-color: #FAF5EB;
  padding: 2px 4px;
  border-radius: 14px;
  text-align: center;
}
.status-expired,
.status-cancel,
.status-end,
.status-red {
  color: #E73A3F;
  background-color: #fbebeb;
  padding: 2px 4px;
  border-radius: 14px;
  text-align: center;
}
.status-active,
.status-unpaid,
.status-upcoming,
.status-blue {
  color: #2778f2;
  background-color: #e8edf7;
  padding: 2px 4px;
  border-radius: 14px;
  text-align: center;
}
.status-inactive,
.status-gray {
  color: #656e7b;
  background-color: #e1e5ef;
  padding: 2px 4px;
  border-radius: 14px;
  text-align: center;
}
