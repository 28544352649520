// Here you can add other styles
.rounded-md {
  border-radius: 12px;
}

.title {
  font-size: 1.5rem;
}

.input-prepend-group {
  position: relative;
  input {
    padding-left: 32px !important;
  }
}
.icon-prepend {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.input-append-group {
  position: relative;
  input {
    padding-right: 32px !important;
  }
}
.icon-append {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
// $accordion-icon-width:                    1.25rem !default;
// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(-180deg) !default;

// .left-icon {
//   .accordion-button {
//     &:not(.collapsed) {
//       &::before {
//         background-image: var(--#{$variable-prefix}accordion-button-active-icon, escape-svg($accordion-button-active-icon));
//         transform: $accordion-icon-transform;
//       }
//     }
//     &::after {
//       content: none;
//     }
//     &::before {
//       flex-shrink: 0;
//       width: $accordion-icon-width;
//       height: $accordion-icon-width;
//       margin-right: 1rem;
//       content: "";
//       background-image: var(--#{$variable-prefix}accordion-button-icon, escape-svg($accordion-button-icon));
//       background-repeat: no-repeat;
//       background-size: $accordion-icon-width;
//       @include transition($accordion-icon-transition);
//     }
//   }
// }

.table {
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-transform: uppercase;
    color: #8B9AB1;
  }
  th[data-row-selection] {
    width: 50px !important;
  }
  th:first-child, td:first-child{
    padding-left: 1.2rem !important;
  }
  th:last-child, td:last-child{
    padding-right: 1.2rem !important;
  }
  .reset-expansion-style {
    background-color: #F3F6FC;
  }
}
nav {
  ul {
    margin-bottom: 0 !important;
  }
}
.pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.react-datepicker-popper {
  z-index: 100 !important;
}

.card {
  &.active {
    border: 2px solid #1A61CD;
  }
}

.header-tabs {
  display: flex;
  background-color: white;
  .tab-item {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d8dbe0;
    padding: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: #f6f6f6;
    }
    &.active {
      border-bottom-width: 3px;
      border-bottom-color: #1A61CD;
    }
  }
}
.btn {
  &:hover, &:focus {
    color: white;
  }
}

.placeholder-upload {
  border: 1px dashed #c4c9d0;
  border-radius: 0.375rem;
  background-color: #F3F6FC;
  overflow: hidden;
  &:hover{
    border: 1px dashed #1A61CD;
  }
}

.center-x {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-y {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tox-tinymce {
  border-radius: 0.375rem !important;
  border-color: $gray-300 !important;
  border-width: 1px !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.table {
  margin-bottom: 0 !important;
}
.table-wrapper {
  overflow: auto;
  min-height: 250px;
}